import { useCallback } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';

import {
  type DtoTag,
  EnumsMediaScene,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useSetQueryParam } from '../../hooks/useQueryParam';
import { apiService } from '../../services/api-service';
import { type Tag } from '../../types';
import { fromMediaDTO } from '../../utils/api-dto';
import { err2s } from '../../utils/common';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { MediaUploader } from '../MediaUploader/MediaUploader';

const HEX_PATTERN = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

type FormData = Pick<
  DtoTag,
  'name' | 'description' | 'icon' | 'background' | 'supportingMedia' | 'styles'
>;

function StylesField() {
  const {
    formState: { errors },
    register,
  } = useFormContext<FormData>();

  return (
    <div className='w-full flex flex-col gap-2'>
      <label>
        <h2 className='text-base font-bold mb-1'>Title text color</h2>
        <input
          className={`w-full h-12.5 ${
            errors.styles?.titleTextColor ? 'field-error' : 'field'
          } mb-0`}
          maxLength={50}
          placeholder='#000000'
          {...register('styles.titleTextColor', {
            pattern: HEX_PATTERN,
          })}
        ></input>
      </label>
      <label>
        <h2 className='text-base font-bold mb-1'>Background color 1</h2>
        <input
          className={`w-full h-12.5 ${
            errors.styles?.backgroundColor1 ? 'field-error' : 'field'
          } mb-0`}
          maxLength={50}
          placeholder='#000000'
          {...register('styles.backgroundColor1', {
            pattern: HEX_PATTERN,
          })}
        ></input>
      </label>
      <label>
        <h2 className='text-base font-bold mb-1'>Background color 2</h2>
        <input
          className={`w-full h-12.5 ${
            errors.styles?.backgroundColor2 ? 'field-error' : 'field'
          } mb-0`}
          maxLength={50}
          placeholder='#000000'
          {...register('styles.backgroundColor2', {
            pattern: HEX_PATTERN,
          })}
        ></input>
      </label>
    </div>
  );
}

function SupportingMediaField() {
  return (
    <div className='font-bold block w-full'>
      <div className='mb-2'>Supporting Media</div>
      <Controller<FormData, 'supportingMedia'>
        name='supportingMedia'
        render={({ field }) => (
          <MediaUploader
            video
            scene={EnumsMediaScene.MediaSceneTag}
            media={fromMediaDTO(field.value?.media)}
            onUploadSuccess={(media) => {
              field.onChange({
                media,
                data: { id: media.id },
              });
            }}
            onMediaDelete={() => {
              field.onChange(null);
            }}
            width='w-full'
            objectFit='object-contain'
          />
        )}
      />
    </div>
  );
}

function NameField() {
  const {
    formState: { errors },
    register,
  } = useFormContext<FormData>();

  return (
    <label>
      <h2 className='text-base font-bold mb-1'>Name</h2>
      <input
        className={`w-full h-12.5 ${
          errors.name ? 'field-error' : 'field'
        } mb-0`}
        maxLength={50}
        placeholder='Must be 1 to 50 characters'
        {...register('name', {
          required: true,
          maxLength: 50,
        })}
      ></input>
    </label>
  );
}

function DescriptionField() {
  const {
    formState: { errors },
    register,
  } = useFormContext<FormData>();

  return (
    <label>
      <h2 className='text-base font-bold mb-1'>Description</h2>
      <textarea
        className={`w-full h-30 py-2 ${
          errors.description ? 'field-error' : 'field'
        } mb-0`}
        placeholder='Max 1000 characters'
        {...register('description', {
          required: false,
          maxLength: 1000,
        })}
      ></textarea>
    </label>
  );
}

function IconField() {
  return (
    <div className='font-bold block w-full'>
      <div className='mb-2'>Icon</div>
      <Controller<FormData, 'icon'>
        name='icon'
        render={({ field }) => (
          <MediaUploader
            video={false}
            scene={EnumsMediaScene.MediaSceneTag}
            media={fromMediaDTO(field.value?.media)}
            onUploadSuccess={(media) => {
              field.onChange({
                media,
                data: { id: media.id },
              });
            }}
            onMediaDelete={() => {
              field.onChange(null);
            }}
            width='w-full'
            objectFit='object-contain'
          />
        )}
      />
    </div>
  );
}

function BackgroundField() {
  return (
    <div className='font-bold block w-full'>
      <div className='mb-2'>Background</div>
      <Controller<FormData, 'background'>
        name='background'
        render={({ field }) => (
          <MediaUploader
            video={false}
            scene={EnumsMediaScene.MediaSceneTag}
            media={fromMediaDTO(field.value?.media)}
            onUploadSuccess={(media) => {
              field.onChange({
                media,
                data: { id: media.id },
              });
            }}
            onMediaDelete={() => {
              field.onChange(null);
            }}
            width='w-full'
            objectFit='object-contain'
          />
        )}
      />
    </div>
  );
}

function TagEditor(props: {
  tag: Tag;
  onSave: (updated: Tag) => void;
  onCancel: () => void;
}) {
  const { tag, onSave, onCancel } = props;

  const form = useForm<FormData>({
    defaultValues: tag,
  });

  const {
    call: submit,
    state: { error },
  } = useLiveAsyncCall(async (data: FormData) => {
    const resp = await apiService.tag.updateTag(tag.id, data);
    onSave(resp.data.tag);
  });

  return (
    <FormProvider {...form}>
      <form
        className='w-full px-7.5 py-5 flex flex-col items-center'
        onSubmit={form.handleSubmit(submit)}
      >
        <header className='text-white text-2xl text-center'>
          Edit Category
        </header>
        <main className='mt-5 w-full flex flex-col gap-4'>
          <div className='flex gap-2'>
            <StylesField />
            <SupportingMediaField />
          </div>
          <div className='flex gap-2'>
            <IconField />
            <BackgroundField />
          </div>
          <NameField />
          <DescriptionField />
        </main>
        <footer className='mt-5'>
          {error && (
            <div className='text-sms text-red-002 mb-1'>{err2s(error)}</div>
          )}
          <div className='w-full flex justify-center items-center gap-2'>
            <button
              type='button'
              className='btn-secondary w-30 h-10'
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn-primary w-30 h-10 mx-1 flex items-center justify-center gap-1'
              disabled={form.formState.isSubmitting}
            >
              {form.formState.isSubmitting ? 'Saving' : 'Save'}
            </button>
          </div>
        </footer>
      </form>
    </FormProvider>
  );
}

export function useTriggerTagEditorModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const setParam = useSetQueryParam();
  return useCallback(
    async (props: { tag: Tag; onSave?: (updated: Tag) => void }) => {
      setParam('tag-id', String(props.tag.id), { replace: true });
      await triggerModal({
        kind: 'custom',
        element: (p) => (
          <ModalWrapper
            containerClassName='w-180'
            borderStyle='gray'
            onClose={p.internalOnCancel}
          >
            <TagEditor
              tag={props.tag}
              onSave={(updated) => {
                props.onSave?.(updated);
                p.internalOnConfirm();
              }}
              onCancel={p.internalOnCancel}
            />
          </ModalWrapper>
        ),
      });
      setParam('tag-id', null, { replace: true });
    },
    [setParam, triggerModal]
  );
}
